@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', 'Roboto', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark: #161718;
  --grey-dark: #1f2124;
  --grey-med: #282c34;
  --grey-light: #525252;
  --grey-bright: #f0f1f2;
  --grey-muted: #3d3d3d;

  --grey-blue-dark: #55699e;
  --grey-blue-med: #bac2d7;
  --grey-blue-light: #dee3f0;
  --blue: #06bcee;
  --purple: #5b2ee6;
  --pink: #e886ff;

  --aqua: aquamarine;
  --red: lightcoral;

  --pad-xsmall: .5rem;
  --pad-small: 1rem;
  --pad-med: 2rem;
  --pad-large: 4rem;
  /* --box-shadow: 0 0 1rem var(--grey-blue-light); */
  --radius: .5rem;
  --header-height: 52px;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* #backgroundPattern {
  position: fixed;
  z-index: -1;
  top: var(--header-height);
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
} */

h1 {
  font-size: 3em;
  color: var(--aqua);
}

h2,h3,h4,h5,h6 {
  margin: 0;
  font-size: 1em;
}

a {
  text-decoration: none;
  color: var(--aqua);
}

button {
  font-family: inherit;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 0;
  border: 1px solid transparent;
  padding: .25rem .5rem;
  white-space: nowrap;
}

button:focus {
  outline: none;
  border: 1px solid white;
}

button:disabled {
  opacity: 0.25;
}

button svg {
  width: 15px;
  height: 15px;
}

/* button svg + span {
  margin-left: .5rem;
} */

.button {
  display: inline-block;
  padding: .25rem .5rem;
  border-radius: var(--radius);
  font-weight: 800;
}

/* .button.default {
  color: var(--aqua);
  background-color: white;
} */

.button.alt {
  color: white;
  background-color: var(--purple);
}

/* Tooltip */
.toolTip-wrapper {
  position: relative;
}

[role="tooltip"] {
  display: none;
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  background-color: var(--grey-light);
  font-weight: 800;
  font-size: .75em;
  padding: .25rem .5rem;
  text-align: center;
  border-radius: var(--radius);
  z-index: 1;
}

[role="tooltip"]:before {
  content: '';
  position: absolute;
  width: 1rem;
  height: .5rem;
  top: -.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--grey-light);
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

[role="tooltip"].align-right {
  left: 0;
  transform: translateX(0%);
}

[role="tooltip"].align-right:before {
  transform: translateX(-125%);
}

[role="tooltip"].align-left {
  right: 0;
  transform: translateX(-75%);
}

[role="tooltip"].align-left:before {
  transform: translateX(75%);
}

.toolTip-wrapper button:hover + [role="tooltip"],  
.toolTip-wrapper button:focus + [role="tooltip"] {  
  display: block;
}

/* Pop up */

.popUp {
  position: absolute;
  z-index: 1;
  background-color: white;
  color: var(--dark);
  padding: 1rem;
  left: 0;
  width: 100%;
  bottom: 0;
}

/* Hint block */
.hintBlock-wrapper {
  padding: var(--pad-small);
}

.hintBlock {
  background-color: white;
  /* border-radius: var(--radius); */
  /* border: 1px dashed var(--purple); */
  padding: var(--pad-small);
  text-align: center;
  color: var(--grey-dark);
  font-weight: 400;
}

/* Main */
body {
  background-color: #171719;
  color: white;
  overflow: hidden;
}

.siteWrapper {
  min-width: 50rem;
}

main {
  display: flex;
}

main:not(#create){
  padding: var(--pad-small);
}

/* Hero */
main.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc( 100vh - 56px);
}

.siteHero {
  max-width: 40rem;
  text-align: center;
}

.siteHero-title {
  margin: 0;
}

/* Form elements */

.inputWrapper {
  display: flex;
  flex: 1 1;
  flex-basis: 50%;
}

.inputWrapper + .inputWrapper {
  margin-left: 1rem;
}

label {
  display: flex;
  align-items: center;
  font-size: small;
  background-color: var(--grey-dark);
  min-width: min-content;
  max-width: max-content;
  white-space: nowrap;
  padding: .5rem;
  line-height: 1;
  font-weight: 800;
}

input {
  display: block;
  width: 100%;
  background-color: var(--grey-med);
  color: white;
  border: 0;
  padding: .5rem;
}

/* Panel */
.sitePanel {
  flex: 1 1;
}

.sitePanel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  padding: var(--pad-small);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent);
}

/* Header */
.siteHeader {
  height: var(--header-height);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  padding: var(--pad-small);
  background-color: var(--grey-dark);
}

.siteHeader a {
  color: white;
}

.siteHeader a.active {
  color: var(--aqua);
}

/* Nav */
.siteNav a {
  padding: 0 .5rem;
}

/* Header auth */

.siteHeader-auth {
  display: flex;
}

/* Shapes & actions nav */

.actionsNav-wrapper {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent);
  /* border-bottom: 1px solid var(--grey-light); */
}

.actionsNav,
.shapesNav {
  display: flex;
}

.actionsNav-wrapper button {
  padding: var(--pad-small);
}

.actionsNav-wrapper button svg {
  width: 20px;
  height: 20px;
}

.actionsNav-wrapper button svg path {
  stroke: var(--aqua);
}

.actionsNav-wrapper button:hover {
  background-color: var(--grey-med);
}

/* SVG Panel */

.sitePanel-svg {
  flex: 2 1;
  position: relative;
}

/* SVG Box */

.svgBox-section-wrapper {
  padding: var(--pad-med);
  overflow: hidden;
  height: calc(100vh - 104px);
}

.svgBox-section {
  position: relative;
  max-height: calc(100vh - 4rem - 104px);
  max-width: calc(100vh - 4rem - 104px);
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.svgBox-section #svgBox {
  position: absolute;
  overflow: visible;
}

/* Grid */
.svgBox-grid {
  position: absolute;
  width: 100%;
  left: 0;
  overflow: visible;
}

.svgBox-grid.small {
  z-index: -1;
}

.svgBox-grid path {
  stroke: var(--grey-bright);
  stroke-width: 0.2;
}

/* SVGBox Controls */

/* #svgBox-controls {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  border-bottom: 1px solid var(--grey-light);
} */

/* Output */

#svgOutput {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.25), transparent);
}

.sitePanel-output {
  flex: 1 1;
}

.sitePanel-output button {
  color: white;
}

.svgOutput {
  border: 1px solid;
  position: absolute;
  z-index: 2;
  background: rgba(20, 20, 20, 0.95)!important;
  font-size: .75em;
  margin: 0;
  padding: 2em!important;
  overflow-x: hidden!important;
}

code {
  white-space: normal;
  width: 100%;
  color: white;
}

/* Layers */

.sitePanel-layers {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.25), transparent);
  overflow-y: scroll;
  height: calc(100vh - 104px);
}

.layerList {
  margin: 0;
  padding: calc(var(--pad-small) / 2);
  list-style: none;
}

.layerList-item {
  position: relative;
  background-color: var(--dark);
  margin-bottom: .5rem;
  cursor: move;
  font-weight: 600;
  font-size: .75em;
  padding: .5rem;
  animation: layerEntered 1s ease;
  animation-fill-mode: forwards;
}

@keyframes layerEntered {
  0% {
    border: 1px solid white;
  }

  100% {
    border: 1px solid transparent;
  }
}

.layerList-item-actions {
  display: flex;
}

.layerList-item-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: salmon;
  font-size: 1rem;
  margin: .75rem 0;
}

.layerList-item-element,
.layerList-item-node-wrapper {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.layerList-item-node-wrapper {
  padding-right: 4rem;
}

.layerList-item-node {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.layerList-item-node-label {
  color: var(--aqua);
  padding: 0;
  margin: .25rem 0;
  background-color: transparent;
  max-width: none;
  font-size: 1rem;
}

.layerList-item-node-label span {
  color: white;
}

.layerList-item-node input {
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  padding: .25rem;
  outline: 1px solid var(--grey-light);
}

.layerList-item-node input:focus {
  outline-color: var(--aqua);
}

.layerList-item-node.is-inactive {
  opacity: 0.35;
}

/* UI Buttons */

.moveButton {
  pointer-events: none;
  top: 1rem;
}

.deleteButton svg path {
  stroke: var(--red);
}

/* Svg collection list */

.svgCollection-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.svgCollection-list-item {
  flex-basis: 33.3%;
  padding: .5rem;
}

.svgCollection-list-item-content {
  background-color: var(--grey-dark);
  border-radius: var(--radius);
}

.svgCollection-list-item svg {
  width: 100%;
}

 circle,
 path,
 line,
 ellipse,
 rect,
 polyline,
 polygon {
   transition: .25s linear;
 }

 /* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: .15rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--dark); 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: salmon; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white; 
}
